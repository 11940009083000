import { useState, useEffect } from 'react';
import { decryptToken } from '../utils/decryptToken';

export const useGetAccessToken = (token, url) => {
  const [accessToken, setAccessToken] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const [tokenLoading, setTokenLoading] = useState(false);

  useEffect(() => {
    const fetchTokenData = async () => {
      if (token) {
        setTokenLoading(true);
        try {
          const data = await decryptToken(token, url);
          setAccessToken(data);
        } catch (error) {
          setTokenError(error.message);
        } finally {
          setTokenLoading(false);
        }
      }
    };

    fetchTokenData();
  }, [token]);

  return {
    accessToken,
    tokenError,
    tokenLoading,
  };
};
